<template>
  <div class="tw-container">
    <a
      v-if="!ownerStores.loading && ownerStores.data.length > 0"
      class="fw-bolder link"
      href="/user/linkAccounts"
      target="_blank"
      >我是賣家~快速前往後台</a
    >
    <div class="mt-3 d-flex flex-wrap justify-content-center">
      <template v-if="!customerStores.loading">
        <template v-if="customerStores.data.length > 0">
          <div
            class="store-card me-2 mb-2 fw-bolder"
            v-for="data in customerStores.data"
            :key="data.id"
          >
            <p class="text-center tw-text-size18 text-break">
              {{ data.store.name }}
            </p>
            <hr class="m-0 my-2" />
            <!-- 賣場開放狀態 -->
            <div class="mb-1">
              開放狀態:
              <span class="text-success" v-if="data.store.opened"
                >賣場開放中</span
              >
              <span class="text-danger" v-else>賣場已關閉</span>
            </div>
            <!-- 審核狀態 -->
            <div>
              審核狀態:
              <span class="text-warning" v-if="data.verify === null"
                >待審核</span
              >
              <span class="text-success" v-else-if="data.verify">審核通過</span>
              <span class="text-danger" v-else>審核未通過</span>
            </div>
            <hr class="m-0 my-2" />
            <div class="row">
              <div class="col-6 p-0 text-center pointer">
                <a
                  :href="`/customer/store/${data.storeId}/shop`"
                  class="card-btn"
                  >前往賣場</a
                >
              </div>
              <div
                class="col-6 p-0 text-center pointer del"
                @click="showModal('withdrawStore', data)"
              >
                退出賣場
              </div>
            </div>
          </div>
        </template>
        <p class="fw-bolder alert alert-danger" v-else>
          您目前尚未加入任何賣場呦!
        </p>
      </template>
      <p class="fw-bolder alert alert-danger" v-else>
        請稍後~正在取得賣場中...
      </p>
    </div>
  </div>
  <!-- 退出賣場的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="withdrawStoreModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">退出賣場</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">確定退出此賣場 ?</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="withdrawStore"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// API
import { GetStoreList } from '@/methods/API/getStoreList'

export default {
  data() {
    return {
      // modal
      withdrawStoreModal: {},
      // data
      selectStores: [],
    }
  },
  created() {
    this.getUserInfoForStoreList('user')
  },
  mounted() {
    this.createModals(['withdrawStoreModal'])
  },
  mixins: [GetStoreList],
  methods: {
    showModal(status, data) {
      this.selectStores = []
      if (status === 'withdrawStore') {
        this.selectStores.push(data)
        this.withdrawStoreModal.show()
      }
    },
    // 退出賣場
    withdrawStore() {
      this.$methods.switchLoading('show')
      const vm = this
      const withdrawStoreApi = `${process.env.VUE_APP_API}/participant/withdrawStore`
      const header = {
        authorization: this.$methods.getCookie('serverToken')
      }
      const data = new FormData()
      data.append('storeId', this.selectStores[0].storeId)
      $.ajax({
        type: 'PUT',
        async: true,
        url: withdrawStoreApi,
        data: data,
        headers: header,
        processData: false,
        contentType: false,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.getUserInfoForStoreList('user')
            vm.withdrawStoreModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.link {
  border: 1px solid black;
  padding: 3px;
  border-radius: 10px;
  &:hover {
    background-color: cadetblue;
    color: white;
  }
}
.store-card {
  background: rgb(255, 248, 210);
  width: 200px;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 1px grey;
}
.del {
  &:hover {
    background-color: rgb(255, 188, 188);
  }
}
</style>